.archk-forms {

    

    $spaceBetween: 100px;
    $cardWidth: 225px;

    .archk-forms-map-card {
        box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    }

    .archk-forms-map {
        background-color: white;

        height: calc(100vh - 200px);
        padding-bottom: 20px;
        white-space: nowrap;
        overflow: auto;
        position: relative;

        .question {
            display: inline-block;
            width: $cardWidth;
            margin-right: $spaceBetween;
            white-space: pre-wrap;
            display: relative;

            &.forked {
                z-index: 99;
            }

            .card-footer i {
                float: right;
                position: relative;
                top: -15px;
            }

            .card-header {
                height: 70px;
                overflow: auto;
            }
            .card-footer {
                height: 42px;
            }

            .archk-forms-line {
                position: absolute;
                right: -$spaceBetween;
                height: 0;
                border-color: black;
                border-top: solid 2px black;
                width: $spaceBetween;
                top: 50%;
                z-index: 2;
            }

            .archk-forms-add-question i {
                cursor: pointer;
                position: absolute;
                right: (-$spaceBetween / 2) - 15;
                top: calc(50% - 14px);
                font-size: 30px;
                display: inline-block;
                color: $green;
                z-index: 3;
                background-color: white;
                border-radius: 100%;
            }

            .archk-forms-line-forked {
                position: absolute;
                right: calc(50%);
                height: $spaceBetween;
                border-color: black;
                border-right: solid 2px black;
                width: 0;
                top: -86px;
                z-index: -2;

            }

            .archk-forms-add-question-forked i {
                cursor: pointer;
                position: absolute;
                right: calc(50% - 14px);
                top: -53px;
                font-size: 30px;
                display: inline-block;
                color: $green;
                z-index: 3;
                background-color: white;
                border-radius: 100%;
            }

            &.dont-show {
                .archk-forms-add-question-forked i,
                .archk-forms-line-forked {
                    display: none;
                }
            }

        }

    }

}
