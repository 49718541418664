.archk-spin {

    -webkit-animation: spin1 2s infinite linear;
    -moz-animation: spin1 2s infinite linear;
    -o-animation: spin1 2s infinite linear;
    -ms-animation: spin1 2s infinite linear;
    animation: spin1 2s infinite linear;

    @-webkit-keyframes spin1 {
        0% { -webkit-transform: rotate(0deg);}
        100% { -webkit-transform: rotate(360deg);}
    }
    @-moz-keyframes spin1 {
        0% { -moz-transform: rotate(0deg);}
        100% { -moz-transform: rotate(360deg);}
    }
    @-o-keyframes spin1 {
        0% { -o-transform: rotate(0deg);}
        100% { -o-transform: rotate(360deg);}
    }
    @-ms-keyframes spin1 {
        0% { -ms-transform: rotate(0deg);}
        100% { -ms-transform: rotate(360deg);}
    }
    @keyframes spin1 {
        0% { transform: rotate(0deg);}
        100% { transform: rotate(360deg);}
    }

}

.z-depth-1 {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.z-depth-2 {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}
.z-depth-3 {
    -webkit-box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}
.z-depth-4 {
    -webkit-box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
}
.z-depth-5 {
    -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
}

.email-content body {
    background: transparent;
    min-height: 0;
}


.system-loader {

    .container {
        height: 100vh;
        width: 100vw;
        font-family: Helvetica;
        background: white;
        z-index: 999;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .loader {
        height: 20px;
        width: 250px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .loader--dot {
        animation-name: loader;
        animation-timing-function: ease-in-out;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        background-color: black;
        position: absolute;
        border: 2px solid white;
    }
    .loader--dot:first-child {
        background-color: lighten($green, 10%);
        animation-delay: 0.5s;
    }
    .loader--dot:nth-child(2) {
        background-color: lighten($green, 5%);

        animation-delay: 0.4s;
    }
    .loader--dot:nth-child(3) {
        background-color: lighten($green, 0%);
        animation-delay: 0.3s;
    }
    .loader--dot:nth-child(4) {
        background-color: lighten($teal, 0%);
        animation-delay: 0.2s;
    }
    .loader--dot:nth-child(5) {
        background-color: lighten($teal, 5%);
        animation-delay: 0.1s;
    }
    .loader--dot:nth-child(6) {
        background-color: lighten($teal, 10%);
        animation-delay: 0s;
    }
    .loader--text {
        position: absolute;
        top: 200%;
        left: 0;
        right: 0;
        width: 4rem;
        margin: auto;
    }
    .loader--text:after {
        content: "Loading";
        font-weight: bold;
        animation-name: loading-text;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }

    @keyframes loader {
        15% {
            transform: translateX(0);
        }
        45% {
            transform: translateX(230px);
        }
        65% {
            transform: translateX(230px);
        }
        95% {
            transform: translateX(0);
        }
    }
    @keyframes loading-text {
        0% {
            content: "Loading";
        }
        25% {
            content: "Loading.";
        }
        50% {
            content: "Loading..";
        }
        75% {
            content: "Loading...";
        }
    }

}

.tooltip {
  z-index: 100000 !important;
}

.raise-1 {
  z-index: 1;
}

.raise-2 {
  z-index: 2;
}

.raise-3 {
  z-index: 3;
}

.raise-4 {
  z-index: 4;
}

.lower-1 {
  z-index: -1;
}

.lower-1 {
  z-index: -2;
}

.lower-3 {
  z-index: -3;
}

.lower-4 {
  z-index: -4;
}

.color-circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    display: inline-block;
    margin-left: $spacer * .5;
    margin-right: $spacer * .5;
}

.avatar-image {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: solid 1px rgba(255,255,255,.3);
}

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.resolved-alert {
    .item-wrapper {
        opacity: .85;
        border: solid 3px $green;
    }
}

body {
    // background: $white !important;
}

.question-list {

    // background: $body-bg;
    background: $white !important;

    .question-list {
        padding-left: 40px;
        margin-top: 20px;
        
    }

    .question {
        border-top: solid 1px $gray-200;
        border-left: solid 1px $gray-200;
        border-right: solid 1px $gray-200;
        padding: 15px 20px;
        padding-bottom: 25px;
    
        // background: rgba(0, 0, 0, 0.016);

        &:last-child {
            border-bottom: solid 1px $gray-200;

        }

        .question-error {
            color: $danger !important;

            .form-control-label {
                color: $danger !important;
            }
        }
    
        .mb-3 {
            margin-bottom: 0 !important;
        }
    
        .form-control {
            font-size: 16px !important;
        }
        .form-control-label {
            font-size: 20px !important;
            color: $black;
            font-weight: bold;
        }
    }
    

}

